<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  methods: {
    changeBodyClass(css) {
      if (css.add) document.body.classList.add(css.add)
      if (css.remove) document.body.classList.remove(css.remove)
      console.log(document.body.className)
    }
  },
  created() {
    this.$root.$on('changeBodyClass', css => {
      this.changeBodyClass(css)
    })
  }
}
</script>
