<template>
  <section class="content-header">
    <nav class="navbar navbar-expand navbar-light navbar-white">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button">
            <i class="fas fa-bars"></i>
          </a>
        </li>
        <li class="nav-item">
          <h4>{{ title }}</h4>
        </li>
        <li class="nav-item d-sm-inline-block" v-html="subTitle"></li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">
            <a href="/adm/index">Home</a>
          </li>
          <li class="breadcrumb-item active">{{ nav }}</li>
        </ol>
      </ul>
    </nav>
  </section>
</template>
<script>
export default {
  name: 'title-nav',
  props: {
    title: {
      type: String,
      default: ''
    },
    nav: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped>
h4 {
  line-height: 40px;
  margin-bottom: 0;
}
</style>
