import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Page from '../components/layout/Page'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Page,
    children: [
      {
        path: '/',
        component: () => import('../views/Index')
      }
    ]
  },
  {
    path: '/user',
    component: Page,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: () => import('../views/user/UserList')
      },
      {
        name: 'detail',
        path: 'detail/:usersn',
        props: true,
        component: () => import('../views/user/UserDetail')
      },
      {
        name: 'withdrawal',
        path: 'user_withdrawal',
        props: true,
        component: () => import('../views/user/UserWithdrawal')
      },
      {
        name: 'user_voucher',
        path: 'user_voucher',
        component: () => import('../views/user/UserVoucher')
      },
      {
        name: 'user_count',
        path: 'user_count',
        component: () => import('../views/user/UserCount')
      },
      {
        name: 'user_login',
        path: 'login',
        component: () => import('../views/user/UserLogin')
      }
    ]
  },
  {
    path: '/payment',
    component: Page,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: () => import('../views/payment/index')
      },
      {
        name: 'day',
        path: 'day',
        component: () => import('../views/payment/day')
      },
      {
        name: 'month',
        path: 'month',
        component: () => import('../views/payment/month')
      },
      {
        name: 'refund',
        path: 'refund',
        component: () => import('../views/payment/refund')
      }
    ]
  },
  {
    path: '/operation',
    component: Page,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'LMS',
        component: () => import('../views/operation/LMS')
      },
      {
        path: 'mail',
        component: () => import('../views/operation/Mail')
      },
      {
        path: 'mail_click',
        component: () => import('../views/operation/MailClick')
      },
      {
        path: 'qna',
        component: () => import('../views/operation/QnA')
      },
      {
        path: 'notice',
        component: () => import('../views/operation/Notice')
      },
      {
        path: 'push',
        component: () => import('../views/operation/Push')
      },
      {
        path: 'faq',
        component: () => import('../views/operation/FAQ')
      },
      {
        path: 'review',
        component: () => import('../views/operation/Review')
      },
      {
        path: 'main',
        component: () => import('../views/operation/MainLecture')
      },
      {
        path: 'comment',
        component: () => import('../views/operation/Comment')
      },
      {
        path: 'event',
        component: () => import('../views/operation/Event')
      }
    ]
  },
  {
    path: '/mainvideo',
    component: Page,
    meta: { requiresAuth: true },
    children: [
      {
        name: '메인Video',
        path: '',
        component: () => import('../views/mainvideo/mainvideo')
      }
    ]
  },
  {
    path: '/system',
    component: Page,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'log',
        component: () => import('../views/system/Log')
      }
    ]
  },
  {
    path: '/lecture',
    component: Page,
    meta: { requiresAuth: true },
    children: [
      {
        name: 'lecture_index',
        path: '',
        component: () => import('../views/lecture/Index')
      },
      {
        nam: 'lecture_edit',
        path: 'edit',
        component: () => import('../views/lecture/Edit')
      },
      {
        name: 'lecture_category',
        path: 'category',
        component: () => import('../views/lecture/Category')
      }
    ]
  },
  {
    path: '/product',
    component: Page,
    meta: { requiresAuth: true },
    children: [
      {
        name: 'product',
        path: '',
        component: () => import('../views/product/index')
      }
    ]
  },
  {
    path: '/site',
    component: Page,
    meta: { requiresAuth: true },
    children: [
      {
        name: 'plusup',
        path: 'plusup',
        component: () => import('../views/site/PlusUp')
      },
      {
        name: 'evest',
        path: 'evestseminar',
        component: () => import('../views/site/Evest')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('../views/sign/LogIn')
  },
  {
    path: '*',
    component: Page,
    children: [
      {
        path: '',
        component: () => import('../components/layout/PageNotFound')
      }
    ]
  }
]

const router = new VueRouter({
  base: process.env.VUE_APP_BASE_PATH,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['user/isLogin']) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
