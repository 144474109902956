<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/adm/index" class="brand-link" align="center">
      <!--
      <img
        src="dist/img/AdminLTELogo.png"
        alt="AdminLTE Logo"
        class="brand-image img-circle elevation-3"
        style="opacity: .8"
      />
      -->
      <span class="brand-text logo_text">FM School</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel d-flex">
        <div class="image"></div>
        <div class="info">
          <a href="javascript:;" class="d-block" alt="logout" v-on:click="logout">
            {{ getUser.ADMIN_ID }}
            <i class="fas fa-sign-out-alt" style="position:absolute;top:10px;right:10px"></i>
          </a>
          <!-- {{ getUser.ADMIN_ID }} -->
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
          with font-awesome or any other icon font library-->
          <li
            class="nav-item has-treeview"
            v-for="menu in menus"
            v-bind:key="menu.name"
            :class="{ 'menu-open': prefixPath == menu.path }"
          >
            <a href="/adm/" class="nav-link" :class="{ active: prefixPath == menu.path }">
              <i class="nav-icon far fa-plus-square"></i>
              <p>
                {{ menu.name }}
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <template v-if="menu.childrens">
              <ul class="nav nav-treeview">
                <li class="nav-item" v-for="submenu in menu.childrens" v-bind:key="submenu.name">
                  <router-link
                    :to="submenu.path"
                    class="nav-link"
                    :class="{ active: $route.path == submenu.path }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>{{ submenu.name }}</p>
                  </router-link>
                </li>
              </ul>
            </template>
          </li>
          <!-- <li class="nav-item has-treeview" style="color:red">
            <a>
              <i class="nav-icon far fa-plus-square" />
              서비스 상태 - 작업필요
            </a>
          </li>

          <li class="nav-item has-treeview" style="color:red">
            <a>KOSCOM 상태 - 작업필요</a>
          </li>
          <li class="nav-item has-treeview" style="color:red">
            <a>서버 리소스 상태 - 작업필요</a>
          </li>

          <li class="nav-item has-treeview" style="color:red">
            <a>스케쥴러 상태(로그,) - 작업필요</a>
          </li> -->
        </ul>
      </nav>
      <!-- /.sidebar-menu -->

      <div class="text-center m-3" v-if="adminLevel === 'SA'">
        <b-button variant="success">
          오늘 가입자 <b-badge variant="light">{{ newUserCnt }}</b-badge>
        </b-button>
      </div>
    </div>
    <b-container> </b-container>

    <!-- /.sidebar -->
  </aside>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { logout, getNewUsertCnt } from '@/api/user'
import { getMenu } from '@/api/code'

export default {
  data() {
    return {
      newUserCnt: 0,
      adminLevel: '',
      menus: [],
      orgMenus: [
        {
          name: '회원 관리',
          path: 'user',
          childrens: [
            { name: '회원 현황', path: '/user' },
            { name: '탈퇴 현황', path: '/user/user_withdrawal' },
            { name: '수강권 지급 현황', path: '/user/user_voucher' },
            { name: '회원수 현황', path: '/user/user_count' },
            { name: '접속 현황', path: '/user/login' }
          ]
        },
        {
          name: '결제 현황',
          path: 'payment',
          childrens: [
            { name: '결제 현황', path: '/payment' },
            { name: '일별', path: '/payment/day' },
            { name: '월별', path: '/payment/month' },
            { name: '환불', path: '/payment/refund' }
          ]
        },
        {
          name: '강좌 관리',
          path: 'lecture',
          childrens: [
            { name: '카테고리 관리', path: '/lecture/category' },
            { name: '강좌 현황', path: '/lecture' },
            { name: '등록/수정', path: '/lecture/edit' }
          ]
        },
        {
          name: '상품 관리',
          path: 'product',
          childrens: [{ name: '등록/수정', path: '/product' }]
        },
        {
          name: '운영관리',
          path: 'operation',
          childrens: [
            { name: 'LMS 전송', path: '/operation/lms' },
            { name: '메일 발송', path: '/operation/mail' },
            { name: '메일 클릭내역', path: '/operation/mail_click' },
            { name: '고객 문의', path: '/operation/qna' },
            { name: 'Push 발송내역', path: '/operation/push' },
            { name: 'FAQ', path: '/operation/faq' },
            { name: '공지', path: '/operation/notice' },
            { name: '리뷰', path: '/operation/review' },
            { name: '메인 강좌', path: '/operation/main' },
            { name: '댓글', path: '/operation/comment' },
            { name: '이벤트', path: '/operation/event' }
          ]
        },
        {
          name: '제휴관리',
          path: 'site',
          childrens: [
            { name: '쿠폰 현황', path: '/site/plusup' },
            { name: '세미나 참여현황', path: '/site/evestseminar' }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['getUser']),
    ...mapActions('user', ['clearUser']),
    prefixPath() {
      const routePathSplit = this.$route.path.split('/')
      return routePathSplit[1]
    }
  },
  methods: {
    logout() {
      logout()
    },
    async getNewUserCnt() {
      const response = await getNewUsertCnt()
      if (response.code === '0000' && response.data) {
        this.newUserCnt = response.data
      } else {
        console.log(`error :${response.message}`)
      }
    },
    async getMenu() {
      const response = await getMenu()
      if (response.code === '0000') {
        this.adminLevel = response.data.admin_level
        //this.$store.commit('user/SET_USER_LEVEL', this.adminLevel)
        this.$store.dispatch('user/updateUserLevel', this.adminLevel)

        const data = response.data.menu
        for (var i = 0; i < this.orgMenus.length; i++) {
          if (data[this.orgMenus[i].path] && data[this.orgMenus[i].path]['authority_r'] === 'Y') {
            this.menus.push(this.orgMenus[i])
          }
        }
        if (this.menus.length > 0 && this.$route.path === '/')
          this.$router.push('/' + this.menus[0].path)
        if (this.adminLevel === 'SA') this.getNewUserCnt()
      }
    }
  },
  created() {
    this.getMenu()
  },
  mounted() {}
}
</script>
<style>
.logo_text {
  color: #fff;
  font-size: 2rem;
  text-shadow: -1px 0 #f2f1f6, 0 1px #f2f1f6, 1px 0 #f2f1f6, 0 -1px #f2f1f6;
  -moz-text-shadow: -1px 0 #f2f1f6, 0 1px #f2f1f6, 1px 0 #f2f1f6, 0 -1px #f2f1f6;
  -webkit-text-shadow: -1px 0 #f2f1f6, 0 1px #f2f1f6, 1px 0 #f2f1f6, 0 -1px #f2f1f6;
}

.cnt {
  color: whitesmoke;
  font-size: 1rem;
}
.cnt span {
  color: red;
}
</style>
