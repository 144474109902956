export function presentAlert(header, message) {
  const id = `customModal${generateRandom(1, 100)}`
  const alert = document.createElement('div')
  const html = `
<div class="modal fade" id="modal-default">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">${header}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>${message}</p>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" id="${id}Ok" data-modal-id="${id}">Ok</button>
      </div>
    </div>
  </div>
</div>
  `
  alert.innerHTML = html
  document.getElementById('app').appendChild(alert)
  document.getElementById(`${id}Ok`).focus()

  window.jQuery(`#${id}Ok, #${id}Close`).click(function() {
    const modalId = window.jQuery(this).attr('data-modal-id')
    document.getElementById(modalId).remove()
  })
}

export const generateRandom = function(min, max) {
  const ranNum = Math.floor(Math.random() * (max - min + 1)) + min
  return ranNum
}

export function RecommendCntAlert(header, message, items) {
  const id = `customModal${generateRandom(1, 100)}`
  const alert = document.createElement('div')
  const html = `
<b-modal ref="my-modal" class="modal fade" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">${header}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>${message}</p>
        <b-table striped hover :${items}="items"></b-table>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" id="${id}Ok" data-modal-id="${id}">Ok</button>
      </div>
    </div>
  </div>
</b-modal>
  `
  alert.innerHTML = html
  document.getElementById('app').appendChild(alert)
  document.getElementById(`${id}Ok`).focus()
  window.jQuery(`#${id}Ok, #${id}Close`).click(function() {
    const modalId = window.jQuery(this).attr('data-modal-id')
    document.getElementById(modalId).remove()
  })
  return id
}
