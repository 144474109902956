import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  BootstrapVue,
  IconsPlugin,
  FormTextareaPlugin,
  ButtonPlugin,
  LayoutPlugin,
  FormInputPlugin,
  BootstrapVueIcons
} from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import VueMomentJS from 'vue-momentjs'

import 'admin-lte/dist/css/adminlte.min.css'
import 'admin-lte/plugins/fontawesome-free/css/all.min.css'
import 'admin-lte/plugins/jquery/jquery.min.js'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
import 'admin-lte/dist/js/adminlte.min.js'
import Modal from './services/myModal'

import TitleNav from './components/layout/TitleNav'
import './utils/filter'
import BootstrapVueTreeview from 'bootstrap-vue-treeview'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.component(TitleNav.name, TitleNav)

Vue.use(Modal)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(ButtonPlugin)
Vue.use(LayoutPlugin)
Vue.use(FormInputPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(Datepicker)
Vue.use(VueMomentJS, moment)

Vue.use(BootstrapVueTreeview)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import '@/assets/styles/custom-bootstrap-vue.css'
