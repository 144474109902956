import axios from 'axios'
// import qs from 'qs'
import store from '../store'
// import router from '../router'
import { presentAlert } from '../utils/ui'

const host = `${location.protocol}//${location.host}`
// const host = process.env.VUE_APP_DEV_API_SERVER
const instance = axios.create({
  baseURL: `${host}/`,
  // timeout: 1000
  headers: {
    // Accept: 'application/text; charset=utf8'
    // 'Content-Type': 'application/text; charset=utf8'
  }
})

// Set the AUTH token for any request
instance.interceptors.request.use(
  async function(config) {
    const isLogin = store.getters['user/isLogin']
    console.log(`isLosing: ${isLogin}`)
    if (isLogin) {
      const token = store.getters['user/getToken']

      config.headers.Authorization = token ? `Bearer ${token}` : ''
      // console.log(`token:${JSON.stringify(config.headers)}`)
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  async function(_response) {
    if (_response.data.code !== '0000') {
      const originalRequest = _response.config
      // const refresh_resp = await refreshAccessToken()
      if (_response.data.code === '0902') {
        const _refresh_resp = await refreshAccessToken()
        if (_refresh_resp.data.code === '0000') {
          var LOGIN_TOKEN = _refresh_resp.data.data.accessToken
          var REFRESH_TOKEN = _refresh_resp.data.data.refreshToken

          console.log(`before Authorization: ${originalRequest.headers.Authorization}`)
          store.commit('user/RESET_TOKEN', { LOGIN_TOKEN, REFRESH_TOKEN })
          originalRequest.headers.Authorization = `Bearer ${LOGIN_TOKEN}`
          console.log(`after Authorization: ${originalRequest.headers.Authorization}`)
          console.log(`accessToken was changed`)
          return axios(originalRequest)
        } else {
          console.log(`_refresh_resp.data is not 0000 > ${_refresh_resp.data.code}`)
          return Promise.resolve(_response)
        }
      } else {
        console.log(`error: ${_response.data.code}`)
        return Promise.resolve(_response)
      }
    } else if (_response.data.code === '0000') {
      //정상인경우
      return _response
    }
  },
  function(error) {
    return Promise.reject(error)
  }
)
/**
 * 결과 처리를 페이지에서 하는 경우
 * @param {String} cmd
 * @param {Object} data
 */
export const axiosPost = (cmd, data) => {
  console.log('axois', cmd, data)
  return instance({
    method: 'post',
    url: `/adm_api/${cmd}`,
    data: data
  })
    .then(_response => {
      if (_response.data) {
        // result_code가 오류인데 result_msg가 없는 경우,
        // 페이지에서 result_msg를 띄울 수 없으므로
        // 통합에러처리
        if (_response.data.code !== '0000' && !_response.data.message) {
          throw new Error(_response.data.code)
        }
        return _response.data
      } else throw new Error('response data is null')
    })
    .catch(error => {
      console.error(error)
      if (error.message === 'admin.error.no_admin_id') {
        alert('ID를 확인해주세요.')
      } else if (error.message === 'admin.admin.error.no_admin_pwd') {
        alert('비밀번호를 확인해주세요')
      } else if (error.message === 'admin.error.invalid_pwd') {
        alert('비밀번호를 확인해주세요')
      } else if (error.message === 'admin.error.not_active') {
        alert('비밀번호를 5회 이상 틀려 계정이 비활성화상태입니다.')
      }
      presentAlert('Error', '', error.message)
    })
}

/**
 * 결과 처리를 페이지에서 하는 경우
 * @param {String} cmd
 * @param {Object} data
 */
export const axiosPostToUrl = (cmd, data) => {
  console.log('axiosPostToUrl', cmd, data)
  return instance({
    method: 'post',
    url: `/adm_api/${cmd}`,
    data: data
  })
    .then(_response => {
      if (_response.data) {
        // result_code가 오류인데 result_msg가 없는 경우,
        // 페이지에서 result_msg를 띄울 수 없으므로
        // 통합에러처리
        if (_response.data.code !== '0000' && !_response.data.message) {
          throw new Error(_response.data.message)
        }
        return _response.data
      } else throw new Error('response data is null')
    })
    .catch(error => {
      console.error(error)

      presentAlert('Error', '', error.data.message)
    })
}

export const axiosUpload = (url, formData) => {
  console.log(`/adm_api/${url}`)
  var config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  return instance
    .post(`/adm_api/${url}`, formData, config)
    .then(_response => {
      // console.log(`upload:${JSON.stringify(_response)}`)
      if (_response.data) {
        // result_code가 오류인데 result_msg가 없는 경우,
        // 페이지에서 result_msg를 띄울 수 없으므로
        // 통합에러처리

        return _response.data
      } else throw new Error('response data is null')
    })
    .catch(error => {
      console.error(`error:${error}`)
      presentAlert('Error', '', error.message)
    })
}

export const axiosGet = url => {
  return instance({
    method: 'get',
    url: `/adm_api/${url}`
  })
    .then(_response => {
      if (_response.data) {
        // result_code가 오류인데 result_msg가 없는 경우,
        // 페이지에서 result_msg를 띄울 수 없으므로
        // 통합에러처리

        if (_response.data.code !== '0000' && !_response.data.message) {
          throw new Error(_response.data.message)

          // return axiosGet(url)
        }
        return _response.data
      } else throw new Error('response data is null')
    })
    .catch(_error => {
      console.log(`axiosGet error :${_error}`)
      // return _error.data
      throw new Error(_error)
    })
}
export const axiosDelete = (url, data) => {
  console.log('axiosDelete')
  return instance({
    method: 'delete',
    url: `/adm_api/${url}`,
    data: data
  })
    .then(_response => {
      if (_response.data) {
        // result_code가 오류인데 result_msg가 없는 경우,
        // 페이지에서 result_msg를 띄울 수 없으므로
        // 통합에러처리
        if (_response.data.code !== '0000' && !_response.data.message) {
          throw new Error(_response.data.message)
        }
        return _response.data
      } else throw new Error('response data is null')
    })
    .catch(_error => {
      alert(`error: ${JSON.stringify(_error)}`)
    })
}

export const axiosPut = (url, data) => {
  console.log(`axiosPut`)
  return instance({
    method: 'put',
    url: `/adm_api/${url}`,
    data: data
  })
    .then(_response => {
      if (_response.data) {
        // result_code가 오류인데 result_msg가 없는 경우,
        // 페이지에서 result_msg를 띄울 수 없으므로
        // 통합에러처리
        if (_response.data.code !== '0000' && !_response.data.message) {
          throw new Error(_response.data.message)
        }
        return _response.data
      } else throw new Error('response data is null')
    })
    .catch(_error => {
      alert(`error: ${JSON.stringify(_error)}`)
    })
}

export const axiosDownload = (url, data) => {
  return instance({
    method: 'get',
    url: `/adm_api/${url}`,
    data: data,
    responseType: 'arraybuffer'
  })
}

export const refreshAccessToken = () => {
  console.log('refresh token')
  // const accessToken = store.dispatch['user/getToken']
  // const refreshToken = store.dispatch['user/getRefreshToken']
  const accessToken = store.getters['user/getToken']
  const refreshToken = store.getters['user/getRefreshToken']
  return instance({
    method: 'post',
    url: '/adm_api/auth/rtoken',
    data: { accessToken, refreshToken }
  })
    .then(_response => {
      if (_response.data) {
        console.log(_response.data)
        if (_response.data.code === '0000') {
          return _response
        }
        if (_response.data.code !== '0000' && !_response.data.message) {
          console.log(`refresh fail, ${_response.data.message}`)
          throw new Error(_response.data.message)
        }

        return _response
      } else throw new Error('response data is null')
    })
    .catch(_error => {
      // console.log(`error: ${JSON.stringify(_error)}`)
      return _error.data
    })
}
