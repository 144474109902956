import { presentAlert } from '../utils/ui'
export default {
  install(Vue) {
    Vue.prototype.$popup = {
      modal(header, message) {
        presentAlert(header, message)
      }
    }
  }
}
